<template>
  <div>
    <!-- begin:: Header -->
    <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between"
      >
        <!-- begin:: Header Menu -->
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
        >
          <div
            v-if="headerMenuEnabled"
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <ul
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
            >
              <template>
                <li class="breadcrumb-item">
                  <span style="font-size: 12px;">
                    KAS BESAR / <span style="font-weight: 600;">LAPORAN TRANSAKSI</span>
                  </span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- end:: Header Menu -->
        <!-- begin:: Header Topbar -->
        <div class="topbar">
          <!--begin: User Bar -->
          <div class="topbar-item">
            <!--begin: Notifications -->
            <ApprovalNotification></ApprovalNotification>
            <!--end: Notifications -->
            <div
              class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span
                class="symbol symbol-30 symbol-circle symbol-xl-30 pr-4 symbol-light-success"
              >
                <img v-if="false" alt="Profile Photo" :src="currentUserPhoto" />
                <span
                  v-if="true"
                  class="symbol-label font-size-h5 font-weight-bold"
                >
                  {{ user_fullname.charAt(0).toUpperCase() }}
                </span>
              </span>
              <span
                class="d-none d-md-inline mr-12"
                v-bind:style="{
                  'font-family': 'lato',
                  'font-size': '12px',
                  'font-weight': '600'
                }"
              >
                <span class="font-weight-bold font-size-base d-md-inline mr-1">
                  HI,
                </span>
                {{ user_fullname }}
              </span>
              <i class="ki ki-arrow-down icon-xs text-muted mr-12"></i>
            </div>

            <div
              id="kt_quick_user"
              ref="kt_quick_user"
              class="offcanvas offcanvas-right p-10"
            >
              <!--begin::Header-->
              <div
                class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
              >
                <h3 class="font-weight-bold m-0">
                  PROFIL USER
                </h3>
                <a
                  href="#"
                  class="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                >
                  <i class="ki ki-close icon-xs text-muted"></i>
                </a>
              </div>
              <!--end::Header-->

              <!--begin::Content-->
              <perfect-scrollbar
                class="offcanvas-content pr-5 mr-n5 scroll"
                style="max-height: 90vh; position: relative;"
              >
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                  <div class="symbol symbol-100 mr-5">
                    <img :src="currentUserPersonalInfo.photo" alt="" />
                    <i class="symbol-badge bg-success"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold font-size-h5 text-dark-75">
                      {{ user_fullname }}
                    </span>
                    <div class="navi mt-2">
                      <span class="navi-link p-0 pb-2">
                        <span class="navi-icon mr-1">
                          <span class="svg-icon svg-icon-lg svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="assets/icons/Mail.svg" />
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                        <span class="navi-text text-muted">
                          {{ email }}
                        </span>
                      </span>
                    </div>
                    <button
                      class="btn btn-light-primary btn-bold"
                      @click="onLogout"
                    >
                      SIGN OUT
                    </button>
                  </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <div class="separator separator-dashed my-7"></div>
              </perfect-scrollbar>
              <!--end::Content-->
            </div>
          </div>
          <!--end: User Bar -->
        </div>
        <!-- end:: Header Topbar -->
      </div>
    </div>
    <!-- end:: Header -->
    <!-- Body -->
    <div style="margin-bottom: 43px;">
      <div>
        <img class="mb-4" src="assets/background_page_title.png" alt="" style="height: 36px; margin-right: 10px;">
        <span style="font-size: 30px; font-weight: 700;">
          LAPORAN TRANSAKSI
        </span>
      </div>
      <div>
        <span>
          MENAMPILKAN DATA LAPORAN TRANSAKSI
        </span>
      </div>
    </div>
    <div class="form-group">
      <template>
        <v-toolbar flat>
          <v-row>
            <v-col cols="1" class="mt-3">
              <span style="color: black; font-size: 14px;">TANGGAL</span>
            </v-col>
            <v-col cols="3">
              <v-menu
                ref="filter_date_from"
                v-model="filter_date_from"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="data_date_filter.date_from"
                    class="vuetify-custom mb-12"
                    solo
                    hide-details
                    v-bind:style="{
                      color: '#c30e0e !important'
                    }"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    style="box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_from"
                  no-title
                  @input="filter_date_from = false"
                  @change="onChangeFilterDateFrom(date_from)"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1" class="mt-3" style="text-align: center;">
              <span style="color: black; font-size: 14px;">S.D.</span>
            </v-col>
            <v-col cols="3">
              <v-menu
                ref="filter_date_to"
                v-model="filter_date_to"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="data_date_filter.date_to"
                    class="vuetify-custom mb-12"
                    solo
                    hide-details
                    v-bind:style="{
                      color: '#c30e0e !important'
                    }"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    style="box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_to"
                  no-title
                  @input="filter_date_to = false"
                  @change="onChangeFilterDateTo(date_to)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-autocomplete
              v-model="data_transaction_report_filter.workgroup_id"
              id="workgroup_id"
              :items="data_workgroup_combobox"
              @click="dataWorkgroup_Combobox"
              item-value="workgroup_id"
              item-text="workgroup_name"
              label="PERUSAHAAN"
              solo
              hide-details
              class="mb-12"
              style="box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
              @change="dataTransactionReport()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="data_transaction_report_filter.bank_id"
              id="bank_id"
              :items="data_bank_combobox"
              @click="dataBank_Combobox"
              item-value="bank_id"
              item-text="bank_name"
              label="BANK"
              solo
              hide-details
              class="mb-12"
              style="box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
              @change="dataTransactionReport()"
            ></v-autocomplete>
          </v-col>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDelete">BATAL</v-btn>
                <v-btn
                  text
                  @click="deleteDataTransactionReport"
                  v-bind:style="{
                    color: '#1e2758 !important'
                  }"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="data_transaction_report"
          :item-key="cashbank_id"
          :options.sync="options"
          :search="search"
          sort-by="Id"
          class="elevation-1 mb-12"
          :loading="loadingTable"
          loading-text="HARAP TUNGGU..."
        >
          <template v-slot:[`item.number`]="{ index }">
            <td>
              {{
                options.page * options.itemsPerPage -
                  options.itemsPerPage +
                  (index + 1)
              }}
            </td>
          </template>
          <template v-slot:[`item.amount_debit`]="{ item }">
            <td>Rp {{ formatNumber(item.amount_debit) }}</td>
          </template>
          <template v-slot:[`item.amount_credit`]="{ item }">
            <td>Rp {{ formatNumber(item.amount_credit) }}</td>
          </template>
          <template v-slot:[`item.current_balance`]="{ item }">
            <td>Rp {{ formatNumber(item.current_balance) }}</td>
          </template>
          <template v-slot:[`item.cashbank_type`]="{ item }">
            <td>
              {{
                item.cashbank_type.substr(0, 2) == "KB"
                  ? "KAS BESAR"
                  : "KAS KECIL"
              }}
            </td>
          </template>
          <template v-slot:footer>
            <div class="v-data-table__wrapper">
              <tfoot
                class="v-data-table-header"
                style="background-color: #e9effd;"
              >
                <th
                  align="center"
                  colspan="5"
                  role="columnheader"
                  scope="col"
                  aria-label="No: Not sorted. Activate to sort ascending."
                  aria-sort="none"
                  class="text-start sortable"
                  style="width: 5000px; min-width: 70px; height: 48px; padding-left: 16px;"
                >
                  <span style="font-size: 15px !important;">AKUMULASI SALDO DEBET & KREDIT</span>
                </th>
                <th
                  align="center"
                  colspan="5"
                  role="columnheader"
                  scope="col"
                  aria-label="No: Not sorted. Activate to sort ascending."
                  aria-sort="none"
                  class="text-start sortable"
                  style="width: 70px; min-width: 70px; height: 48px; padding-right: 16px;"
                >
                  <span style="font-size: 15px !important;">{{
                    formatNumber(totalBalance)
                  }}</span>
                </th>
              </tfoot>
            </div>
          </template>
        </v-data-table>
      </template>
    </div>
    <!-- End Body -->
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<style scoped>
.vuetify-custom >>> .v-text-field__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-select__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-input__icon i {
  color: #1e2758 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ApprovalNotification from "@/view/pages/dashboard/ApprovalNotificationBell.vue";
import localStorage from "@/core/services/store/localStorage";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";
import { formatDate } from "@/helpers/helper.js";

export default {
  name: "cashbank-in",

  components: {
    ApprovalNotification
  },

  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    dialogDeleteDetail: false,
    create_data_transaction_report: [],
    update_data_transaction_report: [],
    delete_data_transaction_report: [],
    data_transaction_report: [],
    data_escrow_notification: [],
    data_bank_guarantee_notification: [],
    data_submission_form_finance_notification: [],
    data_workgroup_combobox: [],
    data_bank_combobox: [],
    data_project_combobox: [],
    data_financings_combobox: [],
    data_transaction_type_combobox: [
      {
        transaction_type_id: "KBKC",
        transaction_type_name: "KAS KECIL"
      },
      {
        transaction_type_id: "KBU",
        transaction_type_name: "UMUM"
      }
    ],
    data_transaction_report_date: {
      cashbank_date: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10)
    },
    add_transaction_report: {
      workgroup_id: "",
      project_id: "",
      financing_id: "",
      financing_name: "",
      guarantee_id: "",
      period_code: "",
      cashbank_desc: "",
      special_cashbank_desc: "",
      cashbank_date: new Date().toISOString().substr(0, 10),
      cashbank_type: "",
      transaction_type: "",
      reference: "",
      account_number: "",
      amount: 0
    },
    data_transaction_report_filter: {
      date_from: new Date().toISOString().substr(0, 10),
      date_to: new Date().toISOString().substr(0, 10),
      workgroup_id: "",
      workgroup_name: "",
      bank_id: "",
      bank_name: ""
    },
    data_date_filter: {
      filter_date_from: false,
      filter_date_to: false,
      date_from: formatDate(),
      date_to: formatDate()
    },
    submitted: false,
    headers: [],
    editedIndex: -1,
    deletedIndex: -1,
    editedItem: {},
    editedItemDetail: {},
    deletedItem: {},
    deletedItemDetail: {},
    defaultItem: {},
    isFirstLoad: true,
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false
    },
    label: "",
    placeholder: "",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    valueWhenIsEmpty: "",
    option: {
      locale: "en-US",
      prefix: "",
      suffix: "",
      length: 20,
      precision: 0
    },
    cashbank_date: "",
    date: "",
    user_role: "",
    seen: false,
    user_id: "",
    user_fullname: "",
    email: "",
    date_from: "",
    date_to: "",
    count_escrow_notification: 0,
    count_bank_guarantee_notification: 0,
    count_submission_form_finance_notification: 0,
    loadingTable: true
  }),

  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentUserPersonalInfo",
      "currentUserPhoto"
    ]),

    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    totalNotification: function() {
      let countEscrowNotification = this.count_escrow_notification;
      let countBankGuaranteeNotification = this
        .count_bank_guarantee_notification;
      let countSubmissionFormFinanceNotification = this
        .count_submission_form_finance_notification;
      let totalNotification = 0;

      totalNotification += parseInt(
        countEscrowNotification +
          countBankGuaranteeNotification +
          countSubmissionFormFinanceNotification
      );

      return totalNotification;
    },

    totalBalance: function() {
      let totalBalance = 0;
      let totalAmountDebit = 0;
      let totalAmountCredit = 0;

      for (let i = 0; i < this.data_transaction_report.length; i++) {
        totalAmountDebit += parseInt(
          this.data_transaction_report[i].amount_debit
        );
        totalAmountCredit += parseInt(
          this.data_transaction_report[i].amount_credit
        );

        totalBalance = totalAmountDebit - totalAmountCredit;
      }

      return totalBalance;
    },

    config() {
      return this.layoutConfig();
    },

    formTitle() {
      let result = "";

      if (this.editedIndex === -1) {
        result = "Buat Laporan Transaksi";
      } else if (this.editedIndex === -2) {
        result = "Ubah Laporan Transaksi Detail";
      } else {
        result = "Ubah Laporan Transaksi";
      }

      return result;
    },

    formInput() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_transaction_report;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formInputNotClear() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_transaction_report_not_null;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formSubmit() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.createDataTransactionReport;
      } else {
        result = this.updateDataTransactionReport;
      }

      return result;
    },

    computedDate() {
      return this.formatDate(this.cashbank_date);
    },

    computedDateModified() {
      return this.formatDate(this.date);
    },

    computedDateFrom() {
      return this.formatDate(this.date_from);
    },

    computedDateTo() {
      return this.formatDate(this.date_to);
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    options: {
      async handler(val) {
        val;
        if (!this.isFirstLoad) await this.dataTransactionReport();
        this.loading = false;
      },
      deep: true
    },

    cashbank_date() {
      this.formInputNotClear.cashbank_date = this.formatDate(
        this.cashbank_date
      );
    },

    date() {
      this.formInputNotClear.date = this.formatDate(this.date);
    },

    date_from() {
      this.data_date_filter.date_from = this.formatDate(this.date_from);
    },

    date_to() {
      this.data_date_filter.date_to = this.formatDate(this.date_to);
    }
  },

  created() {
    this.initHeders();
  },

  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });

    this.load();

    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    this.user_id = localStorage.getLocalStorage("UID");
    this.user_fullname = localStorage.getLocalStorage("User_Fullname");
    this.email = localStorage.getLocalStorage("User_Email");
    this.user_role = localStorage.getLocalStorage("User_Role");

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Laporan Transaksi" }]);
  },

  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    getHeaders() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "cashbank_id",
          align: " d-none"
        },
        { text: "NO", value: "number", width: "80px" },
        {
          text: "TANGGAL",
          sortable: true,
          value: "cashbank_date"
        },
        {
          text: "NO VOUCHER",
          sortable: true,
          value: "no_voucher"
        },
        {
          text: "PERUSAHAAN",
          sortable: true,
          value: "workgroup_name"
        },
        {
          text: "PROYEK",
          sortable: true,
          value: "project_name"
        },
        {
          text: "COST",
          sortable: true,
          value: "financing_name"
        },
        {
          text: "DESKRIPSI",
          sortable: true,
          value: "cashbank_desc"
        },
        {
          text: "DESKRIPSI KHUSUS",
          sortable: true,
          value: "special_cashbank_desc",
          align: (localStorage.getLocalStorage("User_Role").toLowerCase() == 'atasan' || localStorage.getLocalStorage("User_Role").toLowerCase() == 'atasan keuangan') ? "" : " d-none"
        },
        {
          text: "TIPE KAS",
          sortable: true,
          value: "cashbank_type"
        },
        {
          text: "DEBET",
          sortable: true,
          value: "amount_debit"
        },
        {
          text: "KREDIT",
          sortable: true,
          value: "amount_credit"
        },
        {
          text: "SALDO",
          sortable: true,
          value: "current_balance"
        }
      ];
    },

    loadDate: function() {
      this.cashbank_date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.cashbank_date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateFrom: function() {
      this.date_from = new Date().toISOString().substr(0, 10);
      this.data_date_filter.date_from = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateTo: function() {
      this.date_to = new Date().toISOString().substr(0, 10);
      this.data_date_filter.date_to = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDate(item) {
      this.$refs.date_transaction_report.save(item);
      this.data_transaction_report_date.cashbank_date = item;
    },

    onChangeFilterDateFrom(item) {
      this.$refs.filter_date_from.save(item);
      this.data_transaction_report_filter.date_from = item;
      this.loadingTable = true;
      this.dataTransactionReport();
    },

    onChangeFilterDateTo(item) {
      this.$refs.filter_date_to.save(item);
      this.data_transaction_report_filter.date_to = item;
      this.loadingTable = true;
      this.dataTransactionReport();
    },

    dataTransactionReport() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "TRANSACTION_REPORTS",
          cashbank_type: "KB",
          date_from: this.data_transaction_report_filter.date_from,
          date_to: this.data_transaction_report_filter.date_to,
          workgroup_id: this.data_transaction_report_filter.workgroup_id,
          bank_id: this.data_transaction_report_filter.bank_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/Cashbank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_transaction_report = response.data;
            this.loadingTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    dataEscrowPaymentAccepted() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "ESCROW_PAYMENT_ACCEPTED",
          payment_accepted: "E",
          status_receipt: 1,
          status_escrow_accepted: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_escrow_notification = response.data;
            this.count_escrow_notification =
              this.user_role.toLowerCase() === "atasan keuangan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankGuaranteeNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_BANK_GUARANTEE",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_guarantee_notification = response.data;
            this.count_bank_guarantee_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubmissionFormNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_FOR_BANK_GUARANTEE",
          submission_type: "BGOI",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_submission_form_finance_notification = response.data;
            this.count_submission_form_finance_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataWorkgroup_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Workgroup",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_workgroup_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBank_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Bank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    async load() {
      Promise.all([
        //await this.dataEscrowPaymentAccepted(),
        //await this.dataBankGuaranteeNotification(),
        //await this.dataSubmissionFormNotification(),
        await this.dataTransactionReport()
      ]).then(function(results) {
        results;
      });
    },

    editItem(item) {
      this.editedIndex = this.data_transaction_report.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.deletedIndex = this.data_transaction_report.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data_transaction_report.splice(this.deletedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.add_transaction_report.workgroup_id = "";
        this.add_transaction_report.project_id = "";
        this.add_transaction_report.financing_id = "";
        this.add_transaction_report.cashbank_desc = "";
        this.add_transaction_report.special_cashbank_desc = "";
        this.add_transaction_report.transaction_type = "";
        this.add_transaction_report.reference = "";
        this.add_transaction_report.amount = 0;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    save(formInput) {
      if (formInput == "add_transaction_report") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_transaction_report[this.editedIndex],
            this.add_transaction_report
          );
        } else {
          this.data_transaction_report.push(this.add_transaction_report);
        }
      } else if (formInput == "edit_transaction_report") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_transaction_report[this.editedIndex],
            this.editedItem
          );
        } else {
          this.data_transaction_report.push(this.editedItem);
        }
      }
      this.close();
    },

    initHeders() {
      this.headers = this.getHeaders();
    },

    formatNumber
  }
};
</script>
